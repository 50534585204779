import { jwtDecode } from "jwt-decode";

class AuthService {
    static async login(username, password) {
        const response = await fetch("https://myradassdogs.org/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, password }),
        });
        if (response.ok) {
            const data = await response.json();
            localStorage.setItem("access_token", data.access_token);
            return { success: true };
        } else {
            const errorData = await response.json();
            return { success: false, error: errorData.error };
        }
    }

    static logout() {
        localStorage.removeItem("access_token");
    }

    static getValidToken() {
        const token = localStorage.getItem("access_token");
        if (!token) return null;

        try {
            const { exp } = jwtDecode(token);
            if (Date.now() >= exp * 1000) {
                this.logout(); // Clear expired token
                return null;
            }
            return token;
        } catch (e) {
            this.logout();
            return null;
        }
    }

    static isAuthenticated() {
        return !!this.getValidToken();
    }

    static isAdmin() {
        const token = this.getValidToken();
        if (!token) return false;
        const { role } = jwtDecode(token);
        return role === "admin";
    }
}

export default AuthService;
