import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, Navigate } from 'react-router-dom';
import Gallery from './components/Gallery';
import Login from './components/Login';
import Register from './components/Register';
import AdminPortal from './components/AdminPortal';
import UploadImage from './components/UploadImage';
import ThemeToggle from './components/ThemeToggle';
import AuthService from './services/AuthService';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import './App.css';

function Navbar({ isAuthenticated, isAdmin, handleLogout }) {
  const location = useLocation();

  return (
    <nav className="navbar bg-[var(--bg-color)] p-4 flex justify-between items-center border-b-4 border-[var(--accent-color)]">
      <div className="flex items-center space-x-4">
        <Link
          to="/"
          className={`App-link ${location.pathname === '/' ? 'active-tab' : ''}`}
        >
          Home
        </Link>
        {isAuthenticated && (
          <Link
            to="/upload"
            className={`App-link ${location.pathname === '/upload' ? 'active-tab' : ''}`}
          >
            Upload
          </Link>
        )}
        {isAdmin && (
          <Link
            to="/admin"
            className={`App-link ${location.pathname === '/admin' ? 'active-tab' : ''}`}
          >
            Admin Portal
          </Link>
        )}
        {!isAuthenticated && (
          <Link
            to="/login"
            className={`App-link ${location.pathname === '/login' ? 'active-tab' : ''}`}
          >
            Login
          </Link>
        )}
      </div>
      <div className="flex items-center space-x-4">
        <ThemeToggle />
        {isAuthenticated && (
          <button
            onClick={handleLogout}
            className="App-link bg-[var(--accent-color)] text-white py-2 px-4 rounded ml-4"
          >
            Logout
          </button>
        )}
      </div>
    </nav>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { theme } = useTheme();

  // Function to update authentication and admin state
  const updateAuthState = async () => {
    const authenticated = await AuthService.isAuthenticated();
    setIsAuthenticated(authenticated);

    if (authenticated) {
      const isAdminUser = await AuthService.isAdmin();
      setIsAdmin(isAdminUser);
    } else {
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    // Call function on initial load to set authentication state
    updateAuthState();
  }, []);

  useEffect(() => {
    // Listen for changes in localStorage to update authentication status
    window.addEventListener("storage", updateAuthState);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", updateAuthState);
    };
  }, []);

  const handleRegister = () => {
    updateAuthState();
  };

  const handleLogin = async () => {
    await updateAuthState();
  };

  const handleLogout = () => {
    AuthService.logout();
    setIsAuthenticated(false);
    setIsAdmin(false);
  };

  return (
    <ThemeProvider>
      <Router>
        <div className={`App min-h-screen flex flex-col ${theme}`}>
          {/* Title Row */}
          <header className="bg-[var(--bg-color)] p-6 text-center border-b-4 border-[var(--accent-color)] sm:text-lg">
            <Link
              to="/"
              className="App-link font-bold text-5xl text-[var(--title-color)] font-accent"
            >
              My Rad-Ass Dogs
            </Link>
            <p className='mt-4'>An Organization</p>
          </header>

          {/* Navbar */}
          <Navbar isAuthenticated={isAuthenticated} isAdmin={isAdmin} handleLogout={handleLogout} />

          {/* Content Area */}
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<Gallery />} />
              <Route
                path="/login"
                element={
                  isAuthenticated ? (
                    <Navigate to="/" />
                  ) : (
                    <Login onLogin={handleLogin} />
                  )
                }
              />
              <Route
                path="/register"
                element={
                  isAuthenticated ? (
                    <Navigate to="/" />
                  ) : (
                    <Register onRegister={handleRegister} />
                  )
                }
              />
              <Route
                path="/admin"
                element={
                  isAuthenticated && isAdmin ? (
                    <AdminPortal />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/upload"
                element={
                  isAuthenticated ? (
                    <UploadImage />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
            </Routes>
          </div>

          {/* Footer */}
          <footer className="bg-[var(--bg-color)] text-white p-4 mt-8 w-full border-t-4 border-[var(--accent-color)]">
            <p className="text-center">&copy; 2024 My Rad Ass Dogs</p>
          </footer>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
