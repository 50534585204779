import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function UploadImage() {
  const [caption, setCaption] = useState('');
  const [image, setImage] = useState(null);
  const fileInputRef = React.createRef();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      alert('Please select an image.');
      return;
    }
    const formData = new FormData();
    formData.append('image', image);
    formData.append('caption', caption);

    try {
      const response = await fetch('https://myradassdogs.org/api/upload', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        },

      });
      const data = await response.json();
      if (response.ok) {
        alert(data.message);
        setCaption('');
        setImage(null);
        fileInputRef.current.value = null; // Clear the file input
        navigate('/');  // Redirect to home after upload
      } else {
        alert(data.error);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while uploading the image.');
    }
  };

  return (
    <div className="container mx-auto my-auto">
      <form onSubmit={handleSubmit} className="p-4">
        <div>
          <label className="block text-gray-700">Caption:</label>
          <input
            type="text"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
            className="border rounded w-full py-2 px-3"
          />
        </div>
        <div className="mt-4">
          <label className="block text-gray-700">Select Image:</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            ref={fileInputRef}
            required
          />
        </div>
        <button
          type="submit"
          className="mt-4 bg-primary text-white py-2 px-auto my-auto rounded"
        >
          Upload
        </button>
      </form>
    </div>
  );
}

export default UploadImage;
