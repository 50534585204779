// src/components/ThemeToggle.js
import React, { useContext } from 'react';
import ThemeContext from '../contexts/ThemeContext';

function ThemeToggle() {
    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <button
            onClick={toggleTheme}
            className="mt-2 mb-4 bg-yellow-500 text-white p-2 rounded-full text-sm"
            title="Switch Theme"
            style={{ position: 'fixed', bottom: 10, right: 10 }}
        >
            {theme === 'light' ? 'Dark Mode' : 'Light Mode'}
        </button>
    );
}

export default ThemeToggle;
