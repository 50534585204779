// src/components/ImageModal.js
import React from 'react';

function ImageModal({ image, onClose }) {
    const handleVote = (imageId) => {
        fetch(`https://myradassdogs.org/api/vote/${imageId}`, {
            method: 'POST',
        })
            .then((res) => res.json())
            .then((data) => {
                // Update the vote count
                image.votes = data.votes;
            })
            .catch((err) => console.error(err));
    };

    return (
        <div className="modal-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
            <div className="bg-[var(--bg-color)] text-[var(--text-color)] rounded-lg max-w-[90vw] max-h-[90vh] p-4 mx-4 overflow-hidden flex flex-col items-center">
                <img
                    onClick={onClose}
                    src={`https://myradassdogs.org/api/images/${image.filename}`}
                    alt={image.caption}
                    className="w-auto h-auto max-h-[60vh] max-w-full rounded-lg cursor-pointer object-contain"
                />
                <div className="mt-4 text-center">
                    <p className="text-2xl font-bold">"{image.caption}"</p>
                    <p className="modal-accent-text text-xl mt-6 ">Is this a good boy?</p>
                </div>
                <div className="mt-4 flex justify-center items-center">
                    <span className="mx-2 text-xl modal-accent-text">YES</span>
                    <button
                        onClick={() => handleVote(image.id)}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg mx-2"
                    >
                        ✅
                    </button>
                    <span className="mx-2 text-xl modal-accent-text">or</span>
                    <button
                        onClick={() => handleVote(image.id)}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg mx-2"
                    >
                        ✅
                    </button>
                    <span className="mx-2 text-xl modal-accent-text">YES</span>
                </div>
            </div>
        </div>
    );
}

export default ImageModal;
