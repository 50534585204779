// src/components/Gallery.js
import React, { useEffect, useState } from 'react';
import ImageModal from './ImageModal';

function Gallery() {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const isAdmin = localStorage.getItem('role') === 'admin';

  useEffect(() => {
    fetch('https://myradassdogs.org/api/images')
      .then((res) => res.json())
      .then((data) => setImages(data))
      .catch((err) => console.error(err));
  }, []);

  const handleDeleteImage = (imageId) => {
    fetch(`https://myradassdogs.org/api/delete_image/${imageId}`, {
      method: 'DELETE',
      credentials: 'include'
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          // Remove the deleted image from the state
          setImages((prevImages) => prevImages.filter((img) => img.id !== imageId));
        } else {
          alert(data.error);
        }
      })
      .catch((err) => console.error(err));
  };


  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
      {images.map((image) => (
        <div key={image.id} className="relative max-w-[400px] mx-auto">
          <div className="relative group">
            {/* Delete Button for Admin Users */}
            {isAdmin && (
              <button
                className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                onClick={() => handleDeleteImage(image.id)}
              >
                X
              </button>
            )}
            <img
              onClick={() => setSelectedImage(image)}
              src={`https://myradassdogs.org/api/thumbnails/${image.filename}`}
              alt={image.caption}
              className="w-full h-auto max-h-[280px] rounded-lg border-4 border-[var(--accent-color)] cursor-pointer object-cover"
            />
            <div
              className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              onClick={() => setSelectedImage(image)}
            >
              <p className="text-white text-xl font-bold">{image.caption}</p>
            </div>
            {/* Display Votes in Cartoon Font */}
            <div className="absolute top-1 left-1 bg-yellow-500 text-black font-[cursive] px-2 py-1 rounded-full text-xl">
              ✅ {image.votes}
            </div>
          </div>
        </div>
      ))}


      {selectedImage && (
        <ImageModal
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
}

export default Gallery;
