import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';

function Login({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const result = await AuthService.login(username, password);
        if (result.success) {
            onLogin();
            navigate('/');  // Redirect to home after login
        } else {
            alert(result.error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="p-4">
            <h2 className="text-2xl font-bold">Login</h2>
            <div>
                <label className="block text-gray-700">Username:</label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="border rounded w-auto py-2 px-3"
                    required
                />
            </div>
            <div className="mt-4">
                <label className="block text-gray-700">Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="border rounded w-auto py-2 px-3"
                    required
                />
            </div>
            <button
                type="submit"
                className="mt-4 bg-primary text-white py-2 px-4 rounded"
            >
                Login
            </button>
        </form>
    );
}

export default Login;
