import React, { useEffect, useState } from 'react';

function AdminPortal() {
    const [images, setImages] = useState([]);

    useEffect(() => {
        // Fetch images from the backend
        const fetchImages = async () => {
            try {
                const response = await fetch('https://myradassdogs.org/api/admin/images', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        // Use SSL to prevent mixed content errors
                        'Content-Security-Policy': 'upgrade-insecure-requests',
                        'Referrer-Policy': 'strict-origin-when-cross-origin',
                        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setImages(data);
                } else {
                    console.error('Failed to fetch images:', response.statusText);
                }
            } catch (err) {
                console.error('Error fetching images:', err);
            }
        };

        fetchImages();
    }, []);

    // Handle exporting images
    const handleExport = () => {
        fetch('https://myradassdogs.org/api/admin/export', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Failed to export images");
                }
                return res.blob();  // Convert the response to a Blob
            })
            .then((blob) => {
                // Create a link element to download the blob
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `rad_dogs_${new Date().toISOString()}.zip`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.error('Error exporting images:', error);
                alert('Failed to export images. Please try again.');
            });
    };


    // Handle deleting an image
    const handleDelete = async (imageId, filename) => {
        const confirmation = window.confirm(
            `Are you sure you want to delete the image: ${filename}? This action cannot be undone.`
        );

        if (confirmation) {
            try {
                const response = await fetch(`https://myradassdogs.org/api/delete_image/${imageId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    },
                });
                if (response.ok) {
                    alert('Image deleted successfully.');
                    // Update the state to remove the deleted image from the view
                    setImages(images.filter(image => image.id !== imageId));
                } else {
                    const errorData = await response.json();
                    alert(`Failed to delete image: ${errorData.error}`);
                }
            } catch (err) {
                console.error('Error deleting image:', err);
                alert('An error occurred while deleting the image.');
            }
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold">Admin Portal</h2>
            <button
                onClick={handleExport}
                className="mt-4 bg-[var(--accent-color)] text-[var(--text-color)] py-2 px-4 rounded"
            >
                Export Images
            </button>
            <div className="grid grid-cols-4 gap-4 mt-4">
                {images.map((image) => (
                    <div key={image.id} className="relative border p-2">
                        <img
                            src={`https://myradassdogs.org/api/thumbnails/${image.filename}`}
                            alt={image.caption}
                            className="w-full h-auto border"
                        />
                        <p className="text-sm mt-2">{image.caption}</p>
                        <button
                            onClick={() => handleDelete(image.id, image.filename)}
                            className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center m-2"
                            title="Delete Image"
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AdminPortal;
